import md5 from 'js/library/utils/md5.js';
import CryptoJS from 'crypto-js';
import { store } from 'js/core/configureStore';

//icones
import alert from 'styles/assets/Messages/Alert.png';
import establishment from 'styles/assets/Messages/Establishment.png';
import news from 'styles/assets/Messages/News.png';
import promotion from 'styles/assets/Messages/Promotion.png';
import triibomania from 'styles/assets/Messages/Triibomania.png';
import provider from 'styles/assets/Messages/Provider.png';
import tribomania from 'styles/assets/Messages/Tribomania.png';

import cfac22 from './cfac22/cfac22';

window['alert'] = alert;
window['establishment'] = establishment;
window['news'] = news;
window['promotion'] = promotion;
window['triibomania'] = triibomania;
window['tribomania'] = tribomania;
window['provider'] = provider;

export function colorText(hex) {
  hex = hex.replace('#', '');

  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Calculate the luminance using the YIQ formula
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Determine if the text color should be white or black based on luminance
  return yiq >= 148 ? '#000000' : '#ffffff';
}

export function hashTriibo1(apiToken, passPhrase, channelToken) {
  let challenge1 = passPhrase + apiToken;
  challenge1 = md5.hexMD5(challenge1);

  let challenge2 = challenge1 + channelToken;
  challenge2 = md5.hexMD5(challenge2);

  return challenge2;
}

//mascaras
export function creditCardMask(value) {
  value = value.replace(/\D/g, '');

  let cardNumber = '';

  if (value.length === 0) {
    cardNumber = '';
  } else if (value.length >= 1 && value.length <= 4) {
    cardNumber = value.substr(0, 4);
  } else if (value.length > 4 && value.length <= 8) {
    cardNumber = value.substr(0, 4) + ' ' + value.substr(4, 4);
  } else if (value.length > 8 && value.length <= 12) {
    cardNumber = value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4);
  } else {
    cardNumber =
      value.substr(0, 4) +
      ' ' +
      value.substr(4, 4) +
      ' ' +
      value.substr(8, 4) +
      ' ' +
      value.substr(12, 4);
  }

  return cardNumber;
}

export function cellPhoneMask(value) {
  value = value.replace(/\D/g, '');

  let cellPhone = '';

  if (value.length === 0 || (value.length === 2 && value === '55')) {
    cellPhone = '';
  } else if (value.length === 1) {
    cellPhone = '+55 (' + value.substr(0, 1);
  } else if (value.length > 1 && value.length <= 4) {
    cellPhone = '+55 (' + value.substr(2, 2);
  } else if (value.length > 4 && value.length <= 9) {
    cellPhone = '+55 (' + value.substr(2, 2) + ') ' + value.substr(4, 5);
  } else {
    cellPhone = '+55 (' + value.substr(2, 2) + ') ' + value.substr(4, 5) + '-' + value.substr(9, 4);
  }

  return cellPhone;
}

export function maskCPF(cpf) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.slice(0, 13);
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
}

export function maskCEP(cpf) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{5})(\d)/, '$1-$2');
  cpf = cpf.slice(0, 9);
  cpf = cpf.replace(/(\d{5})(\d{3})$/, '$1-$2');
  return cpf;
}

export function maskCnpj(v) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.slice(0, 17);

  v = v.replace(/(\d{4})(\d{2})$/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
}

//fim mascaras

export function encrypt(msg, key) {
  if (!msg) return null;

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);

  return json.ciphertext.toString(CryptoJS.enc.Base64);
}

export function decrypt(msg, key) {
  if (!msg) return null;
  msg = decodeURIComponent(msg);

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(msg),
    },
    myEncrypt.pass,
    options
  );
  return json.toString(CryptoJS.enc.Utf8);
}

export function findIndexInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] === value) {
        x = i;
      }

      return x;
    });
  }

  return x;
}

export function findValueInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] !== undefined && array[type] === value) {
        x = array;
      }

      return x;
    });
  }

  return x;
}

export function detectmob() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export function formatUrl(data) {
  //wifi_list -> mapa do wifi

  let url = '';

  if (data.action === 'browser' || data.action === 'webview') {
    url = data.url;
  } else if (data.action === 'establishment_info') {
    url = '/estabelecimento/?id=' + data.itemId;
  } else if (
    data.action === 'promotion_businessPartner' ||
    data.action === 'promotion_qrCode' ||
    data.action === 'promotion_selfValidation' ||
    data.action === 'promotion_takeVoucher' ||
    data.action === 'product_qrCode' ||
    data.action === 'product_selfValidation'
  ) {
    url = '/oferta/?id=' + data.itemId;
  } else if (
    data.action === 'promotion_list' ||
    data.action === 'product_list' ||
    data.action === 'establishment_list'
  ) {
    url = '/ofertas/?id=' + data.cardId;
  }

  return url;
}

export function formatDate(millis) {
  if (millis !== undefined) {
    const date = new Date(millis);
    return date.toLocaleDateString();
  } else {
    return null;
  }
}

export function isValidDate(dateString) {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = dateString?.match(regex);
  if (!match) {
    return false;
  }

  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const year = parseInt(match[3], 10);

  if (month < 1 || month > 12) {
    return false;
  }
  if (day < 1 || day > 31) {
    return false;
  }

  const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
}

function isLeapYear(year) {
  if (year % 4 !== 0) return false;
  if (year % 100 !== 0) return true;
  if (year % 400 !== 0) return false;
  return true;
}

// Converte AAAA-MM-DD para timestamp
export const convertDate = (value, type) => {
  const oneDayInMilliseconds = 86400000;
  const initDate = 75540000;
  const finalDate = 10790000;
  let timeStamp = new Date(value).getTime() + oneDayInMilliseconds;
  switch (type) {
    case 'start':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds - initDate);
    case 'final':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds + finalDate);
    default:
      return timeStamp;
  }
};

// Converte DD-MM-AAAA para timestamp
export const convertToTimestamp = (date) => {
  if (!date) return null;
  const [day, month, year] = date.split('/');
  if (day && month && year && day.length === 2 && month.length === 2 && year.length === 4) {
    const dateObject = new Date(Date.UTC(year, month - 1, day));
    const timestampValue = dateObject.getTime();
    return timestampValue;
  } else {
    return null;
  }
};

//CONVERTE MILISEGUNDOS PARA DATA DD/MM/AAAA
export function convertToDateDefault(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();

  converteddDate = day + '/' + month + '/' + year;

  return converteddDate;
}

export const convertDateDefault = (date) => {
  if (!isNaN(date)) {
    const dateObject = new Date(date);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }
};

//CONVERTE MILISEGUNDOS PARA DATA AAAA-MM-DD
export function convertToDate(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();

  converteddDate = year + '-' + month + '-' + day;

  return converteddDate;
}

export function formatPhone(value) {
  value = value.replace(/\D/g, '');

  return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 4);
}

export function getLastUserInfo() {
  const userInfoAuxiliar = localStorage.getItem('userInfoAuxiliar');

  const userInfo =
    userInfoAuxiliar !== undefined &&
    userInfoAuxiliar !== null &&
    typeof JSON.parse(userInfoAuxiliar) === 'object'
      ? JSON.parse(userInfoAuxiliar)
      : !store.getState().codeInputModel.userInfo
      ? store.getState().userFormModel.userInfo
      : store.getState().codeInputModel.userInfo;

  //lógica para retornar a informação de edição dos dados
  if (store.getState().editUserInfoModel.userInfo) {
    return userInfo.uId === store.getState().editUserInfoModel.userInfo.uId &&
      userInfo.updateDate < store.getState().editUserInfoModel.userInfo.updateDate
      ? store.getState().editUserInfoModel.userInfo
      : userInfo;
  } else {
    return userInfo;
  }
}

export function placeIsOpen(hours) {
  let open = false;

  const currentTime = new Date();

  if (hours !== undefined && hours[currentTime.getDay()].ativo) {
    if (
      (currentTime.getHours() >
        parseInt(hours[currentTime.getDay()].horarioInicio.split(':')[0], 10) ||
        (currentTime.getHours() ===
          parseInt(hours[currentTime.getDay()].horarioInicio.split(':')[0], 10) &&
          currentTime.getMinutes() >=
            parseInt(hours[currentTime.getDay()].horarioInicio.split(':')[1], 10))) &&
      (currentTime.getHours() <
        parseInt(hours[currentTime.getDay()].horarioFim.split(':')[0], 10) ||
        (currentTime.getHours() ===
          parseInt(hours[currentTime.getDay()].horarioFim.split(':')[0], 10) &&
          currentTime.getMinutes() <=
            parseInt(hours[currentTime.getDay()].horarioFim.split(':')[1], 10)))
    ) {
      open = true;
    }
  }

  return open;
}

export function getUrlVariables() {
  let GET = {};
  if (document.location.toString().indexOf('?') !== -1) {
    let query = document.location
      .toString()
      .replace(/^.*?\?/, '')
      .replace(/#.*$/, '')
      .split('&');

    for (let i = 0, l = query.length; i < l; i++) {
      let aux = decodeURIComponent(query[i]).split('=');
      GET[aux[0]] = aux[1];
    }
  }

  return GET;
}

export function generateRandom(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function validateDuration(registrationDate, days) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;
    const date = Math.floor((mkTimeCurrent - mkTime) / 60 / 60 / 24);

    if (date > days || registrationDate === null) {
      return false;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}

export function validateRegistrationDate(registrationDate, minutes) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;

    const date = Math.floor((mkTimeCurrent - mkTime) / 60);

    if (date > minutes || registrationDate === null) {
      return false;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Android';
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function startMatomo() {
  let userInfo = getLastUserInfo();

  var _paq = (window._paq = window._paq || []);

  _paq.push(['enableLinkTracking']);
  (function () {
    var u = 'https://matomo.triibo.com.br/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', cfac22('idMatomo')]);
    if (userInfo !== null && userInfo !== undefined) _paq.push(['setUserId', userInfo.uId]);

    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
}

export function trackEventMatomo(location, event, element, elementName) {
  const elementType = `element:${element}; elementName:${elementName}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', location, event, elementType]);
}

export function trackEventMatomoElementId(location, event, element, elementId) {
  const elementType = `element:${element}; elementId:${elementId}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', location, event, elementType]);
}

export function trackEventMatomoVisit(locationName) {
  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', locationName, 'visualizar', window.location.href, 0]);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function darkenColor(hex, amount) {
  // Remove o símbolo # se estiver presente
  hex = hex.replace(/^#/, '');

  // Converte a cor hexadecimal para valores RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Reduz os valores RGB
  r = Math.max(0, r - amount);
  g = Math.max(0, g - amount);
  b = Math.max(0, b - amount);

  // Converte os valores RGB de volta para hexadecimal
  let darkenedHex =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0');

  return darkenedHex;
}

export const randomizeNumbersString = (str) => {
  let newStr = str?.replace(/[0-9]/g, `${Math.floor(Math.random() * 10)}`);

  return newStr;
};

// export const clubeId = window.location.host.split(".")[0];
export function getClubIdEnvironment() {
  const { origin } = window.location;

  if (origin === 'http://localhost:3000' || origin === 'http://192.168.8.173:3000') {
    return 'clubedev1';
  } else {
    if (
      window.location.host.indexOf('seuclube.org') >= 0 ||
      window.location.host.indexOf('nossoclube.org') >= 0
    ) {
      return window.location.host.split('.')[0];
    } else {
      return window.location.host;
    }
  }
}

export function isValidURL(url) {
  const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(:\d+)?(\/[^\s]*)?$/i;
  return urlPattern.test(url);
}

export function isFrameUrl(url) {
  if (url.indexOf('/pagina-completa/') > 0 || url.indexOf('/pagina-interna/') > 0) {
    return true;
  } else return false;
}
