import React, { useContext, useEffect, useState } from 'react';

// STYLES
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from '@mui/material';
import * as S from './styles';
import Input from '../DesignSystem/Input';

// APIS
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { editUserPartner } from 'js/library/utils/API/apiEditUserPartner';

// COMPONENTS
import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';

// OTHERS
import axios from 'axios';
import phone from 'phone';
import { SignUpContext } from 'js/context/SignUpContext';
import { useTranslation } from 'react-i18next';
import {
  colorText,
  convertToTimestamp,
  findValueInArray,
  getLastUserInfo,
  maskCPF,
  maskCnpj,
  randomizeNumbersString,
  convertDateDefault,
  isValidDate,
  trackEventMatomo,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const UFs = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export default function EditUser() {
  const { t } = useTranslation();
  const { formFields } = useContext(SignUpContext);

  const dispatch = useDispatch();
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;
  const userPersistInfo = getLastUserInfo();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [valuesForm, setValuesForm] = useState({});
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    let data = {};

    if (formFields.length > 0) {
      getUserPartner(partnerId, userPersistInfo.uId).then((userInfo) => {
        formFields.forEach((field, index) => {
          switch (field?.type) {
            case 'root':
              if (field.fieldType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : false,
                };
              } else if (field.fieldType === 'date') {
                const date = new Date(userInfo[field.fieldName]);

                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : '',
                };
              }
              break;

            case 'addressList':
              if (userInfo?.addressList?.length > 0) {
                const address = userInfo?.addressList[0];

                address
                  ? (data = { ...data, addressList: { [field.fieldName]: address } })
                  : (data = { ...data, addressList: { [field.fieldName]: {} } });
              }
              break;

            case 'contactList':
              if (field.fieldType === 'email') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                  const dataEmail = findValueInArray(
                    userInfo.contactList,
                    'type',
                    field.fieldName
                  ).value;
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: dataEmail },
                  };
                } else {
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: '' },
                  };
                }
              } else if (field.fieldType === 'cellPhone') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldType)) {
                  const dataCellPhone = phone(
                    findValueInArray(userInfo.contactList, 'type', field.fieldType).value
                  );

                  const maskFilter = phoneMaskArray.filter(
                    (mask) => mask.ddd === dataCellPhone.countryCode
                  );

                  data = {
                    ...data,
                    contactList: {
                      ...data.contactList,
                      [field.fieldName]: {
                        mask: { ...maskFilter[0] },
                        phone: dataCellPhone.phoneNumber.split(maskFilter[0].ddd)[1],
                      },
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  contactList: {
                    ...data.contactList,
                    [field.fieldName]: findValueInArray(
                      userInfo.contactList,
                      'type',
                      field.fieldName
                    ).value,
                  },
                };
              }
              break;

            case 'documentList':
              if (field.fieldType === 'cpf') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCPF(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else if (field.fieldType === 'cnpj') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCnpj(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  documentList: {
                    ...data.documentList,
                    [field?.fieldName]: findValueInArray(
                      userInfo?.documentList,
                      'type',
                      field?.fieldType
                    )?.value,
                  },
                };
              }
              break;

            case 'optInList': {
              const optIn = `optIn_${field.optInId !== undefined ? field.optInId : ''}`;
              data = {
                ...data,
                optInList: {
                  ...data.optInList,
                  [optIn]: {
                    accept: findValueInArray(userInfo.optInList, 'optInId', field.optInId)
                      ? findValueInArray(userInfo.optInList, 'optInId', field.optInId).accept
                      : false,
                  },
                },
              };
              break;
            }

            case 'others':
              if (field.inputType === 'date') {
                const date = new Date(userInfo[field.fieldName]);
                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else if (field.inputType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: { accept: userInfo[field.fieldName] },
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName],
                };
              }
              break;

            default:
              break;
          }
          if (index === formFields.length - 1) {
            setUserInfo(userInfo);
            setValuesForm(data);
            setLoadingForm(false);
          }
        });
      });
    }
  }, [formFields, formFields.length, partnerId, userPersistInfo.uId]);

  useEffect(() => {
    trackEventMatomoVisit('MeusDados');
  }, []);

  const handleChangeCep = (cep, fieldNameAddress) => {
    if (cep.length === 9) {
      const formatedCEP = cep?.replace('-', '');
      axios
        .get(`https://viacep.com.br/ws/${formatedCEP}/json/`)
        .then((response) => {
          response = response.data;
          if (response.erro) {
            toast.error('CEP não encontrado!');
          }

          setValuesForm({
            ...valuesForm,
            addressList: {
              ...valuesForm.addressList,
              [fieldNameAddress]: {
                ...valuesForm.addressList[fieldNameAddress],
                zipCode: cep,
                city: response.localidade,
                neighborhood: response.bairro,
                state: response.uf,
                streetAve: response.logradouro,
                streetNumber: '',
              },
            },
          });
        })
        .catch(() => {
          console.log('Erro ao obter informações do CEP');
        });
    }
  };

  const handleCountryChange = (e, field) => {
    //transforma a string em um array a partir da vírgula
    setValuesForm({
      ...valuesForm,
      contactList: {
        ...valuesForm.contactList,
        [field.fieldName]: {
          ...valuesForm.contactList[field.fieldName],
          mask: {
            ...phoneMaskArray.filter((mask) => mask.ddd === e.target.value)[0],
          },
        },
      },
    });
  };

  const handleInputChange = (event, input) => {
    const name = event.target.name;
    let checked = event.target.checked;
    let value = event.target.value;
    let formattedValue = '';
    if (input.inputType === 'date') {
      value = value.replace(/\D/g, '');
      if (value.length > 0) {
        formattedValue = value.substring(0, 2);
      }
      if (value.length > 2) {
        formattedValue += '/' + value.substring(2, 4);
      }
      if (value.length > 4) {
        formattedValue += '/' + value.substring(4, 8);
      }
    }

    if (name === 'zipCode' && value.length > 5) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }

    switch (input.type) {
      case 'root':
        if (input.inputType === 'date')
          return setValuesForm({ ...valuesForm, [name]: formattedValue });
        if (input.fieldType === 'checkbox') {
          return setValuesForm({ ...valuesForm, [name]: checked });
        } else {
          return setValuesForm({ ...valuesForm, [name]: value });
        }
      case 'others':
        return input.inputType === 'checkbox'
          ? setValuesForm({ ...valuesForm, [name]: { accept: value } })
          : setValuesForm({ ...valuesForm, [name]: value });

      case 'addressList':
        return setValuesForm({
          ...valuesForm,
          addressList: {
            ...valuesForm.addressList,
            [input.fieldName]: {
              ...(valuesForm.addressList && valuesForm.addressList[input.fieldName]),
              [name]: value,
            },
          },
        });

      case 'contactList':
        return input.fieldType === 'email'
          ? setValuesForm({
              ...valuesForm,
              contactList: { ...valuesForm.contactList, [name]: value },
            })
          : input.fieldType === 'cellPhone'
          ? setValuesForm({
              ...valuesForm,
              contactList: {
                ...valuesForm.contactList,
                [name]: {
                  ...valuesForm.contactList[name],
                  mask: !valuesForm.contactList[name]?.mask
                    ? {
                        ...phoneMaskArray.filter((mask) => mask.ddd === '+55')[0],
                      }
                    : { ...valuesForm.contactList[name].mask },
                  phone: value.replace(/\D/g, ''),
                },
              },
            })
          : setValuesForm({
              ...valuesForm,
              contactList: { ...valuesForm.contactList, [name]: value },
            });

      case 'documentList':
        return input.fieldType === 'cpf'
          ? setValuesForm({
              ...valuesForm,
              documentList: { ...valuesForm.documentList, [name]: maskCPF(value) },
            })
          : input.fieldType === 'cnpj'
          ? setValuesForm({
              ...valuesForm,
              documentList: { ...valuesForm.documentList, [name]: maskCnpj(value) },
            })
          : setValuesForm({
              ...valuesForm,
              documentList: { ...valuesForm.documentList, [name]: value },
            });

      case 'optInList': {
        const optIn = `optIn_${input.optInId !== undefined ? input.optInId : ''}`;
        return setValuesForm({
          ...valuesForm,
          optInList: { ...valuesForm.optInList, [optIn]: { accept: checked } },
        });
      }

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let cellPhoneInvalid = false;

    let userInfoObj = {};
    let addressList = [];
    let contactList = [];
    let documentList = [];
    let optInList = [];

    let stopIteration = false;

    formFields.forEach((field) => {
      if (stopIteration) return;
      switch (field.type) {
        case 'root':
        case 'others':
          if (field.fieldType === 'date') {
            if (!isValidDate(valuesForm[field.fieldName])) {
              setLoading(false);
              toast.warn('Por favor, insira uma data válida!');
              stopIteration = true;
            }
            userInfoObj[field.fieldName] = convertToTimestamp(valuesForm[field.fieldName]);
          } else {
            userInfoObj[field.fieldName] = valuesForm[field.fieldName];
          }
          break;

        case 'addressList':
          addressList.push({
            zipCode: valuesForm.addressList[field.fieldName].zipCode,
            city: valuesForm.addressList[field.fieldName].city,
            extra: valuesForm.addressList[field.fieldName].extra,
            streetAve: valuesForm.addressList[field.fieldName].streetAve,
            streetNumber: valuesForm.addressList[field.fieldName].streetNumber,
            state: valuesForm.addressList[field.fieldName].state,
            neighborhood: valuesForm.addressList[field.fieldName].neighborhood,
          });

          userInfoObj.addressList = addressList;
          break;

        case 'contactList':
          if (field.fieldType === 'cellPhone') {
            const formatMask =
              '+' + valuesForm.contactList[field.fieldName].mask.fullMask.replace(/\D/g, '');

            const formatCellPhone =
              valuesForm.contactList[field.fieldName] &&
              `${valuesForm.contactList[field.fieldName].mask.ddd}${valuesForm.contactList[
                field.fieldName
              ].phone.replace(/\D/g, '')}`;

            if (
              valuesForm.contactList[field.fieldName] &&
              formatCellPhone.length < formatMask.length
            ) {
              cellPhoneInvalid = true;
            } else {
              cellPhoneInvalid = false;
            }

            contactList.push({
              type: field.fieldName,
              value: formatCellPhone,
            });
          } else {
            contactList.push({
              type: field.fieldName,
              value: valuesForm.contactList[field.fieldName],
            });
          }

          userInfoObj.contactList = contactList;
          break;

        case 'documentList':
          if (field.fieldType === 'cpf') {
            documentList.push({
              type: field.fieldName,
              value: valuesForm.documentList[field.fieldName]?.replace(/\D/g, ''),
            });
          } else if (field.fieldType === 'cnpj') {
            documentList.push({
              type: field.fieldName,
              value: valuesForm.documentList[field.fieldName]?.replace(/\D/g, ''),
            });
          } else {
            documentList.push({
              type: field.fieldName,
              value: valuesForm.documentList[field.fieldName],
            });
          }

          userInfoObj.documentList = documentList;
          break;

        case 'optInList': {
          const optIn = `optIn_${field.optInId}`;
          if (valuesForm.optInList && valuesForm.optInList[optIn]?.accept) {
            optInList.push({
              accept: valuesForm.optInList[optIn].accept,
              dateAcceptance: Date.now(),
              optInId: field.optInId,
              type: field.label,
              version: 1,
            });
            userInfoObj.optInList = optInList;
          }
          break;
        }

        default:
          break;
      }
    });

    if (
      valuesForm.addressList &&
      valuesForm.addressList.endereco.zipCode &&
      valuesForm.addressList.endereco.zipCode.replace(/\D/g, '').length < 8
    ) {
      setLoading(false);
      return toast.warn('O CEP precisa ter 8 digitos.');
    }

    if (valuesForm.contactList.cpf && valuesForm.contactList.cpf.replace(/\D/g, '').length < 11) {
      setLoading(false);
      return toast.warn('Número de CPF inválido.');
    }

    if (valuesForm.contactList.cnpj && valuesForm.contactList.cnpj.replace(/\D/g, '').length < 14) {
      setLoading(false);
      return toast.warn('Número de CNPJ inválido.');
    }

    if (cellPhoneInvalid) {
      setLoading(false);
      return toast.warn('Número de celular inválido.');
    }

    //--------------------------------------------------
    userInfoObj.partnerId = seuClubePersist.partnerId;
    userInfoObj.partnerName = seuClubePersist.clubeId;
    userInfoObj.updateDate = new Date().getTime();

    if (stopIteration) return;
    editUserPartner(userPersistInfo.uId, seuClubePersist.clubeId, userInfoObj)
      .then((res) => {
        dispatch({
          type: 'EDIT_USER_INFO_FULFILLED',
          payload: {
            userInfo: {
              ...res,
              triiboId: userPersistInfo.triiboId,
              uId: userPersistInfo.uId,
            },
          },
        });
        setLoading(false);
        toast.success('Dados atualizados com sucesso.');
      })
      .catch((err) => {
        dispatch({
          type: 'EDIT_USER_INFO_REJECTED',
          payload: err,
        });
        setLoading(false);
        toast.error('Erro ao atualizar dados.');
      });
  };

  // RENDERIZA OS INPUTS CONFORME O FORMFIELD RECEBIDO VIA API
  const renderInputs = (input) => {
    const validationsInput = {
      aliasName: '12',
    };

    const inputValue = valuesForm[input.fieldName];

    switch (input.type) {
      case 'root':
      case 'others':
        if (input.fieldType === 'checkbox') {
          return (
            <S.ContainerInput>
              <FormGroup>
                <FormControlLabel
                  checked={valuesForm ? valuesForm[input.fieldName] : false}
                  onChange={(e) => handleInputChange(e, input)}
                  id={input.fieldName}
                  name={input.fieldName}
                  required={input.required}
                  disabled={
                    !valuesForm[input.fieldName] || valuesForm[input.fieldName] === ''
                      ? false
                      : !input.allowEdition
                  }
                  control={<Checkbox />}
                  label={input.label}
                />
              </FormGroup>
            </S.ContainerInput>
          );
        } else {
          if (input.inputType === 'date') {
            return (
              <Input
                // type={'others'}
                label={input.required ? input.label + '*' : input.label}
                value={inputValue?.includes('NaN') ? '' : inputValue}
                handleChange={(e) => handleInputChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                maxLength={10}
                disabled={
                  !userInfo?.[input.fieldName] || !userInfo?.[input.fieldName] === ''
                    ? false
                    : !input.allowEdition
                }
              />
            );
          }
          return (
            <Input
              type={input.inputType}
              label={input.required ? input.label + '*' : input.label}
              value={inputValue}
              handleChange={(e) => handleInputChange(e, input)}
              id={input.fieldName}
              name={input.fieldName}
              required={input.required}
              maxLength={validationsInput[input.fieldName] || Infinity}
              disabled={
                !userInfo?.[input.fieldName] || !userInfo?.[input.fieldName] === ''
                  ? false
                  : !input.allowEdition
              }
            />
          );
        }
      case 'addressList':
        return (
          <S.ContentAddress>
            <h3>{input.fieldName}:</h3>
            <Input
              type="text"
              label="Cep do Endereço*"
              value={
                valuesForm.addressList !== undefined
                  ? valuesForm.addressList[input.fieldName]?.zipCode
                  : ''
              }
              handleChange={(e) => {
                handleInputChange(e, input);
                handleChangeCep(e.target.value, input.fieldName);
              }}
              id="cep"
              name="zipCode"
              required
              maxLength="9"
            />
            <S.ContentAgroupInputAddres>
              <Input
                type="text"
                label="Rua*"
                value={
                  valuesForm.addressList !== undefined
                    ? valuesForm.addressList[input.fieldName]?.streetAve
                    : ''
                }
                disabled={!inputValue === '' ? false : !input.allowEdition}
                handleChange={(e) => handleInputChange(e, input)}
                id="rua"
                name="streetAve"
                required
              />
              <Input
                type="text"
                label="Número*"
                value={
                  valuesForm.addressList !== undefined
                    ? valuesForm.addressList[input.fieldName]?.streetNumber
                    : ''
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="numero"
                name="streetNumber"
                required
              />
            </S.ContentAgroupInputAddres>
            <S.ContentAgroupInputAddres>
              <Input
                type="text"
                label="Bairro*"
                value={
                  valuesForm.addressList !== undefined
                    ? valuesForm.addressList[input.fieldName]?.neighborhood
                    : ''
                }
                disabled={!inputValue === '' ? false : !input.allowEdition}
                handleChange={(e) => handleInputChange(e, input)}
                id="bairro"
                name="neighborhood"
                required
              />
              <Input
                type="text"
                label="Cidade*"
                value={
                  valuesForm.addressList !== undefined
                    ? valuesForm.addressList[input.fieldName]?.city
                    : ''
                }
                disabled={
                  valuesForm.addressList !== undefined &&
                  valuesForm.addressList[input.fieldName]?.city
                    ? true
                    : false
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="cidade"
                name="city"
                required
              />
              <FormControl
                sx={{
                  display: 'flex',
                  height: '71px',
                  justifyContent: 'space-between',
                }}
                fullWidth
              >
                <label>UF*</label>
                <Select
                  id="uf-select"
                  value={
                    valuesForm.addressList !== undefined
                      ? valuesForm.addressList[input.fieldName]?.state?.toUpperCase() || ''
                      : ''
                  }
                  disabled={
                    valuesForm.addressList !== undefined &&
                    valuesForm.addressList[input.fieldName]?.state?.toUpperCase()
                      ? true
                      : false
                  }
                  onChange={(e) => handleInputChange(e, input)}
                  required
                  name="state"
                  sx={{
                    height: '42px',
                    width: '100%',
                    borderRadius: '10px',
                    padding: '0.7rem',
                    outline: 'none',
                  }}
                  placeholder="UF"
                >
                  {UFs.map((uf) => (
                    <MenuItem key={uf} value={uf}>
                      {uf}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Input
                type="text"
                label="UF*"
                value={
                  
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="uf"
                name="state"
                required
                maxLength="2"
              /> */}
            </S.ContentAgroupInputAddres>
            <Input
              type="text"
              label="Complemento"
              value={
                valuesForm.addressList !== undefined
                  ? valuesForm.addressList[input.fieldName]?.extra
                  : ''
              }
              handleChange={(e) => handleInputChange(e, input)}
              id="complemento"
              name="extra"
            />
          </S.ContentAddress>
        );

      case 'contactList':
        return input.fieldType === 'cellPhone' ? (
          <S.ContainerInput>
            <label>
              {input.label}
              {input.required && '*'}
            </label>
            <S.BoxCellPhone>
              <S.Adornment>
                <select
                  value={
                    valuesForm.contactList
                      ? valuesForm.contactList[input.fieldName]?.mask.ddd
                      : '+55'
                  }
                  disabled={
                    userInfo?.contactList[input.fieldName]?.mask?.ddd?.length === 0
                      ? false
                      : !input.allowEdition
                  }
                  onChange={(e) => handleCountryChange(e, input)}
                >
                  {phoneMaskArray.map((country, index) => (
                    <option
                      style={{
                        fontFamily: 'Source Sans Pro, sans-serif',
                      }}
                      key={country.id}
                      value={country.ddd}
                    >
                      {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
              </S.Adornment>

              <S.Input
                mask={
                  valuesForm.contactList &&
                  valuesForm.contactList[input.fieldName] &&
                  valuesForm.contactList[input.fieldName].mask
                    ? valuesForm.contactList[input.fieldName].mask.mask
                    : '(99) 99999-9999'
                }
                value={
                  valuesForm.contactList &&
                  valuesForm.contactList[input.fieldName] &&
                  valuesForm.contactList[input.fieldName].phone
                    ? valuesForm.contactList[input.fieldName].phone
                    : ''
                }
                placeholder={
                  valuesForm.contactList
                    ? valuesForm.contactList[input.fieldName]?.mask.mask
                    : randomizeNumbersString('(99) 99999-9999')
                }
                onChange={(e) => handleInputChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                disabled={
                  !valuesForm?.contactList[input?.fieldName]?.phone ||
                  valuesForm?.contactList[input?.fieldName]?.phone === ''
                    ? false
                    : !input?.allowEdition
                }
              />
            </S.BoxCellPhone>
          </S.ContainerInput>
        ) : (
          <Input
            type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={valuesForm.contactList && valuesForm.contactList[input.fieldName]}
            handleChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
            disabled={
              !userInfo?.[input.fieldName] || !userInfo?.[input.fieldName] === ''
                ? false
                : !input.allowEdition
            }
          />
        );

      case 'documentList':
        return (
          <Input
            mask={
              input.fieldType === 'cpf'
                ? '999.999.999-99'
                : input.fieldType === 'cnpj'
                ? '99.999.999/9999-99'
                : null
            }
            type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={valuesForm.documentList && valuesForm.documentList[input.fieldName]}
            handleChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
            disabled={
              !userInfo?.[input.fieldName] || !userInfo?.[input.fieldName] === ''
                ? false
                : !input.allowEdition
            }
          />
        );

      case 'optInList':
        return (
          <S.ContainerInput>
            <FormGroup>
              <FormControlLabel
                checked={
                  valuesForm.optInList
                    ? valuesForm.optInList[`optIn_${input.optInId}`]?.accept
                    : false
                }
                onChange={(e) => handleInputChange(e, input)}
                id={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                name={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                required={input.required}
                disabled={
                  !valuesForm.optInList[`optIn_${input.optInId}`]?.accept ||
                  valuesForm.optInList[`optIn_${input.optInId}`]?.accept === ''
                    ? false
                    : !input.allowEdition
                }
                control={<Checkbox />}
                label={input.label}
              />
            </FormGroup>
          </S.ContainerInput>
        );

      default:
        break;
    }
  };

  return (
    <S.Container className="paper-container">
      <h1>{t('profile.edit')}</h1>
      {loadingForm ? (
        <CircularProgress
          style={{ margin: '0 auto', color: seuClubePersist.colorPrimary }}
          size={50}
        />
      ) : (
        <S.Form onSubmit={handleSubmit}>
          {formFields?.map((field, index) => (
            <div key={index}>
              {renderInputs(field, index)}
              <br />
              {field?.type === 'optInList' && (
                <a href={field.link} target="_blank" rel="noreferrer">
                  {t('profile.terms')}
                </a>
              )}
            </div>
          ))}

          <S.Button
            onClick={() => trackEventMatomo('MeusDados', 'tocar', 'button', 'Salvar')}
            type="submit"
            $bgColor={seuClubePersist.colorPrimary}
            disabled={loading}
            style={{ color: colorText(seuClubePersist.colorPrimary) }}
          >
            {loading ? (
              <CircularProgress
                style={{ color: colorText(seuClubePersist.colorPrimary) }}
                size={20}
              />
            ) : (
              t('profile.save')
            )}
          </S.Button>
        </S.Form>
      )}
    </S.Container>
  );
}
